/*------------------------------------------------------------------
[Table of contents]

1. .landing
2. .landing-info
3. .landing-form
-------------------------------------------------------------------*/
/*------------------
    1. LANDING
------------------*/
.landing {
  width: 100%;
  height: 100%;
  background: url('../../img/landing/landing-background.jpg') no-repeat left center;
  background-size: cover;
  position: fixed;
  top: 0;
  left: 0;

  .landing-decoration {
    width: 64%;
    height: 140%;
    border-radius: 50%;
    background: url('../../img/landing/dot-texture.png') repeat left top $color-body-underlay;
    position: absolute;
    top: -20%;
    right: -32%;
    pointer-events: none;
  }
}

/*----------------------
    2. LANDING INFO
----------------------*/
.landing-info {
  width: 584px;
  position: absolute;
  top: 16%;
  left: 13%;

  .logo {
    display: flex;
    justify-content: center;
  }

  .landing-info-pretitle,
  .landing-info-title,
  .landing-info-text {
    color: $white;
    text-align: center;
  }

  .landing-info-pretitle,
  .landing-info-title {
    text-transform: uppercase;
  }

  .landing-info-pretitle {
    margin-top: 36px;
    font-size: $fs-24;
    font-weight: $weight-medium;
  }

  .landing-info-title {
    font-family: $font-secondary;
    font-size: $fs-60;
    font-weight: $weight-black;
    position: relative;
    top: -10px;
  }

  .landing-info-text {
    width: 384px;
    margin: 30px auto 0;
    @include paragraph(medium);
    font-weight: $weight-medium;
  }

  .tab-switch {
    margin-top: 90px;
  }
}

/*----------------------
    3. LANDING FORM
----------------------*/
.landing-form {
  width: 484px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 13%;

  .form-box {
    position: absolute;
    top: 50%;
    left: 0;

    &:first-child {
      margin-top: -313px;
    }

    &:last-child {
      margin-top: -370px;
    }
  }
}

/*--------------------
    MEDIA QUERIES 
--------------------*/
@media screen and (min-width: 2560px) {
  .landing-info {
    top: 25%;
  }

  .landing-form {
    right: 20%;
  }
}

@media screen and (min-width: 3840px) {
  .landing-info {
    top: 35%;
    left: 18%;
  }

  .landing-form {
    right: 25%;
  }
}

@media screen and (max-width: 1500px) {
  .landing {
    .landing-decoration {
      display: none;
    }
  }

  .landing-info {
    left: 6%;

    .landing-info-text {
      margin-top: 10px;
    }

    .tab-switch {
      margin-top: 40px;
    }
  }

  .landing-form {
    right: 80px;
  }
}

@media screen and (max-width: 1365px) {
  .landing {
    padding: 80px 0 100px;
    position: static;
  }

  .landing-info {
    width: 100%;
    position: static;

    .landing-info-text {
      display: none;
    }
  }

  .landing-form {
    width: 100%;
    margin-top: 80px;
    position: static;

    .form-box {
      margin: 0 auto;
      position: static;

      .form-box-decoration {
        display: none;
      }

      &:first-child,
      &:last-child {
        margin-top: 0;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .landing-form {
    .form-box {
      width: 90%;
    }
  }
}

@media screen and (max-width: 500px) {
  .landing-info {
    .landing-info-title {
      font-size: $fs-60;
    }

    .tab-switch {
      width: 90%;
      margin: 20px auto 0;

      .tab-switch-button {
        width: 50%;
      }
    }
  }

  .landing-form {
    margin-top: 60px;
  }
}
/*------------------------------------------------------------------
[Table of contents]

1. .error-section
-------------------------------------------------------------------*/
/*-----------------------
    1. ERROR SECTION
-----------------------*/
.error-section {
  width: 100%;
  height: 100%;
  background: url('../img/landing/404-bg.png') no-repeat left top, url('../img/logo/logo.png') right 40px top 40px no-repeat, url('../img/landing/dot-texture.png') repeat left top, $color-body-underlay;
  background-size: contain, auto, auto;
  position: fixed;
  top: 0;
  left: 0;

  .error-section-title {
    color: $white;
    font-family: $font-secondary;
    font-size: $fs-253;
    font-weight: $weight-black;
    position: absolute;
    top: 280px;
    left: 220px;
  }

  .error-section-info {
    width: 420px;
    position: absolute;
    top: 400px;
    right: 270px;
  }

  .error-section-subtitle {
    color: $color-text-3;
    font-size: $fs-104;
    font-weight: $weight-bold;
    text-transform: uppercase;

    & + {
      .error-section-text {
        margin-top: 30px;
      }
    }
  }

  .error-section-text {
    margin-top: 20px;
    font-size: $fs-18;
    font-weight: $weight-medium;
    line-height: (32 / 18) * 1em;
  }

  .button {
    width: 160px;
    margin-top: 50px;
  }
}

/*--------------------
    MEDIA QUERIES 
--------------------*/
@media screen and (min-width: 2560px) {
  .error-section {
    .error-section-title {
      top: 35%;
      left: 15%;
    }

    .error-section-info {
      top: 45%;
      right: 20%;
    }
  }
}

@media screen and (max-width: 1366px) {
  .error-section {
    .error-section-title {
      top: 185px;
      left: 145px;
    }

    .error-section-info {
      top: 270px;
      right: 140px;
    }
  }
}

@media screen and (max-width: 1365px) {
  .error-section {
    background: url('../img/landing/dot-texture.png') repeat left top $color-body-underlay;
    background-size: auto;
    padding: 120px 0;
    position: static;
  
    .error-section-title {
      color: $color-text;
      font-size: $fs-60;
      position: static;
      text-align: center;
    }
  
    .error-section-info {
      width: 90%;
      margin: 0 auto;
      position: static;
      text-align: center;
    }
  
    .error-section-subtitle {
      margin-top: 60px;
      font-size: $fs-36;
    }
  
    .button {
      margin: 50px auto 0;
    }
  }
}
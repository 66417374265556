/*------------------------------------------------------------------
[Table of contents]

1. .product-preview
2. .product-category-box
-------------------------------------------------------------------*/
/*--------------------------
    1. PRODUCT PREVIEW
--------------------------*/
.product-preview {
  border-radius: 12px;
  background-color: $color-box-background;
  box-shadow: $shadow-light;

  &.fixed-height {
    height: 360px;
  }

  &.small {
    background-color: transparent;
    box-shadow: none;

    .product-preview-image {
      height: 144px;
      border-radius: 12px;
    }

    .product-preview-info {
      padding: 20px 0 0;

      .text-sticker {
        right: -8px;
      }

      .product-preview-title {
        font-size: $fs-14;
      }
    }
  }

  &.tiny {
    display: flex;
    background-color: transparent;
    box-shadow: none;

    .product-preview-image {
      width: 94px;
      height: 60px;
      margin-right: 20px;
      border-radius: 12px;

      &.short {
        width: 60px;
      }
    }

    .product-preview-info {
      padding: 4px 0 0;

      .product-preview-title {
        font-size: $fs-14;
      }

      .product-preview-category {
        margin-top: 6px;
      }

      .product-preview-creator {
        margin-top: 6px;
        color: $color-text-alt-2;
        font-size: $fs-12;
        font-weight: $weight-bold;

        a {
          color: $color-text-alt-2;
          font-weight: $weight-bold;
        }
      }
    }
  }

  &.micro {
    display: flex;
    background-color: transparent;
    box-shadow: none;

    .product-preview-image {
      width: 68px;
      height: 44px;
      margin-right: 12px;
      border-radius: 12px;
    }

    .product-preview-info {
      padding: 6px 0 0;

      .product-preview-title {
        font-size: $fs-14;
      }

      .product-preview-category {
        margin-top: 6px;
      }
    }
  }

  .product-preview-image {
    height: 180px;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }

  .product-preview-info {
    padding: 28px;
    position: relative;

    .text-sticker {
      position: absolute;
      top: -14px;
      right: 14px;
    }

    .product-preview-title,
    .product-preview-category {
      a {
        color: $color-text;
        font-weight: $weight-bold;
      }
    }
  
    .product-preview-title {
      font-size: $fs-16;
      font-weight: $weight-bold;
    }
  
    .product-preview-category {
      margin-top: 8px;
      padding-left: 14px;
      font-size: $fs-12;
      font-weight: $weight-bold;
      position: relative;

      &.digital {
        &::before {
          border-color: $color-category-digital;
        }
      }

      &.physical {
        &::before {
          border-color: $color-category-physical;
        }
      }

      &::before {
        content: '';
        width: 8px;
        height: 8px;
        border-radius: 50%;
        border: 2px solid $black;
        position: absolute;
        top: 1px;
        left: 0;
      }
    }
  
    .product-preview-text {
      margin-top: 20px;
      color: $color-text-alt;
      font-size: $fs-14;
      font-weight: $weight-medium;
      line-height: (20 / 14) * 1em;
    }

    .button {
      margin-top: 36px;
    }
  }

  .product-preview-meta {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 53px;
    padding: 0 28px;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    background-color: $color-box-background-alt;
    border-top: 1px solid $color-divider;
  }

  .product-preview-author {
    padding-left: 26px;
    position: relative;

    .product-preview-author-image {
      position: absolute;
      top: 1px;
      left: 0;
    }

    .product-preview-author-title {
      font-size: $fs-10;
      font-weight: $weight-medium;
    }

    .product-preview-author-text {
      margin-top: 1px;
      font-size: $fs-12;
      font-weight: $weight-bold;

      a {
        color: $color-text;
        font-weight: $weight-bold;
      }
    }
  }
}

/*-----------------------------
    1. PRODUCT CATEGORY BOX
-----------------------------*/
.product-category-box {
  display: block;
  height: 142px;
  padding: 26px 0 0 28px;
  border-radius: 12px;
  box-shadow: $shadow-light;
  
  &.category-all {
    background: url('../../img/marketplace/category/01.png') no-repeat right top, $gradient-category-all;

    .product-category-box-tag {
      color: $color-category-all;
    }
  }

  &.category-featured {
    background: url('../../img/marketplace/category/02.png') no-repeat right top, $gradient-category-featured;

    .product-category-box-tag {
      color: $color-category-featured;
    }
  }

  &.category-digital {
    background: url('../../img/marketplace/category/03.png') no-repeat right top, $gradient-category-digital;

    .product-category-box-tag {
      color: $color-category-digital;
    }
  }

  &.category-physical {
    background: url('../../img/marketplace/category/04.png') no-repeat right top, $gradient-category-physical;

    .product-category-box-tag {
      color: $color-category-physical;
    }
  }

  .product-category-box-title {
    color: $white;
    font-size: $fs-18;
    font-weight: $weight-bold;
  }

  .product-category-box-text {
    margin-top: 4px;
    color: $white;
    font-size: $fs-14;
    font-weight: $weight-medium;
  }

  .product-category-box-tag {
    display: inline-block;
    height: 20px;
    margin-top: 36px;
    padding: 0 8px;
    border-radius: 200px;
    background-color: $white;
    font-size: $fs-12;
    font-weight: $weight-bold;
    text-transform: uppercase;
    line-height: 20px;
  }
}